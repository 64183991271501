// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("/opt/build/repo/src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-contact-me-js": () => import("/opt/build/repo/src/pages/contact-me.js" /* webpackChunkName: "component---src-pages-contact-me-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-work-js": () => import("/opt/build/repo/src/pages/my-work.js" /* webpackChunkName: "component---src-pages-my-work-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

